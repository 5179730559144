import React from "react";

export function PatientPortalButton() {
  return (
    <span className="sv-headerbar-fixed-link text-decoration-none ml2">
      <a
        className="text-decoration-none"
        href="https://app.eyecloudpro.com/site/!login?sid=5C241BA24405C1755C8C3373345C7FF0704A0191FDE0B69AE91EC7E31B6D02D9"
        target="_blank"
      >
        <div className="sv-btn sv-btn-secondary caps">patient portal</div>
      </a>
    </span>
  );
}
